<template class="login">
	<div>
		<div class="bg">
			
		</div>
		<div class="loginform">
			<img src="../../assets/login.png" >
			<el-input type="text" v-model.trim="userName" placeholder="请输入用户名"  prefix-icon="el-icon-user"></el-input>
			<el-input type="password" @keyup.enter.native="login_btn" v-model.trim="userPass" placeholder="请输入密码" prefix-icon="el-icon-message"></el-input>
			<el-button type="primary" @click='login_btn' plain>登录</el-button>
		</div>	
	</div>
	
</template>

<script>


	export default {
		data() {
			return{
				userName:'',
				userPass:'',
				inputData:{}
			}
		},
		methods:{
			login_btn(){
				if(this.userName == ''){
					this.$message.error('请填写您的账号');
				}else if(this.userPass == ''){
					this.$message.error('请填写您的密码');
				}else{
					let that = this
					// 这个方法便是精髓所在 arr.includes(1),arr.includes(2)
					//登录成功先添加一些假的数据，方便操作
					/*
						权限分为
						1.超级管理----1
						2.管理员----2
						3.财务----3
						4.门店主----4
					*/
					//需将用户信息存入缓存当中，vuex一刷新数据就消失了
					this.$axios.post("/login",{
						name:this.userName,
						password:this.userPass
					}).then(function(res){
						if(res.data.code == '0'){
							that.$message({
							  message: res.data.message,
							  type: 'success'
							});
							window.localStorage.setItem('userInfo',JSON.stringify(res.data.data))
							
							// 权限
							let type = JSON.parse(localStorage.getItem('userInfo'))
							let qxian = JSON.stringify(type.q_id)
							let qx1 = [qxian.slice(0,1),qxian.slice(1,2),qxian.slice(2,3),qxian.slice(3,4)]
							window.localStorage.setItem('quanxian',qx1)

							setTimeout(function(){
								that.$router.push('/user') 
							},500)
						}else if(res.data.code == '2'){
							that.$message({
								message:res.data.message,
								type:'error'
							});
							alert('登录已失效，请重新登录')
						}else{
							that.$message({
							  message: res.data.message,
							  type: 'error'
							});
						}
					})
				}
			},
		
		},
		mounted() {
			let arr1 = [1,2,3,5,4]
			//利用include这个API，若有多个权限的话这 进行位运算
			
			//将地址的信息存入缓存中(防止以后调用)
			let arr = []
			this.$axios.post("/chinaCitie",{}).then(function(res){
				let data = res.data.data
				data.forEach(function(val,ind){
					let children = []
					for(let i = 0; i < val.city.length;i++){
						children.push({
							'label':val.city[i].name,
							'value':val.city[i].name
						})
					}
					let obj = {
						'label' : val.name,
						'value' : val.name,
						'children' : children
					}
					arr.push(obj)
				})
				window.localStorage.setItem('space',JSON.stringify(arr))
			})
		}
	}
</script>

<style scoped="scoped" lang="less">
	.bg{
		width: 100%;
		height: 100vh;
		background: url(../../assets/backgroud.png) no-repeat center center;
	}
	.loginform{
		position: absolute;
		left: 50%;
		top: 30%;
		transform: translateX(-50%) translateY(-50%);
		width:20%;
		background: transparent;
		padding: 40px;
		border-radius: 50px;
		border: 1px solid white;
		color: white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		&>img{
			opacity: 0.8;
		}
	}
	.el-input{
		margin-top: 15px;
	}
	.el-button{
		margin-top: 30px;
		width: 50%;
	}

</style>
